<template id="optasetup">
  <b-container fluid>
    <b-card v-if="team">
      <b-row align-v="center">
        <b-col cols="1">
          <b-img
            v-if="team"
            :src="
              team.squadra ? team.squadra.logo_url : '/assets/images/team.png'
            "
            height="80"
          />
        </b-col>
        <b-col cols="3">
          <h4 v-if="team">
            {{ team.name }}
          </h4>
        </b-col>
        <b-col class="text-center">
          <h5 v-if="game">
            <b-img
              :src="
                game.team_home.squadra
                  ? game.team_home.squadra.logo_url
                  : '/assets/images/team.png'
              "
              fluid
              :width="50"
            ></b-img
            >{{
              game.team_home.squadra
                ? game.team_home.squadra.name
                : game.team_home.name
            }}
            -
            {{
              game.team_away.squadra
                ? game.team_away.squadra.name
                : game.team_away.name
            }}
            <b-img
              :src="
                game.team_away.squadra
                  ? game.team_away.squadra.logo_url
                  : '/assets/images/team.png'
              "
              fluid
              :width="50"
            ></b-img>
          </h5>
        </b-col>
        <b-col class="text-center">
          <p class="text-muted" v-if="game">
            {{ game.score }}<br />
            {{ game.date | formatDate }}<br />
            {{ game.league ? game.league.name : "" }}
          </p>
        </b-col>
        <b-col cols="1">
          <b-btn size="sm" variant="info" title="Info" v-b-toggle.collapse
            ><b-icon icon="info-circle"></b-icon
          ></b-btn>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-collapse id="collapse" class="mb-1">
          <b-row align-v="center" class="mt-2 mb-4">
            <b-col>
              <p>
                <strong>Micro basic</strong>: index calcolato sulle skills base
                (senza algoritmi) di tipo micro del gruppo ruolo di riferimento,
                senza normalizzare i valori tra 0 e 1, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo (Micro/Weigth Player
                Basic Skill Setup)
              </p>
              <p>
                <strong>Macro basic</strong>: index calcolato su tutte le skills
                base (senza algoritmi), senza normalizzare i valori tra 0 e 1,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo di
                riferimento (Micro/Weigth Player Basic Skill Setup)
              </p>
              <p>
                <strong>Micro raw</strong>: index calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, senza
                normalizzare i valori tra 0 e 1, moltiplicate per i relativi
                pesi impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro raw</strong>: index calcolato su tutte le skills
                (con algoritmi), senza normalizzare i valori tra 0 e 1,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo di
                riferimento (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro</strong>: index calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro</strong>: index calcolato su tutte le skills (con
                algoritmi), con la normalizzare dei valori delle skilla tra 0 e
                1 sui giocatori coinvolti nella partita, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo di riferimento
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro norm</strong>: index normalizzato tra 0 e 100 sui
                giocatori coinvolti nella partita, calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro norm</strong>: index normalizzato tra 0 e 100 sui
                giocatori coinvolti nella partita, calcolato su tutte le skills
                (con algoritmi), con la normalizzare dei valori delle skilla tra
                0 e 1 sui giocatori coinvolti nella partita, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo di riferimento
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro group norm</strong>: index normalizzato tra 0 e
                100 sui giocatori dello stesso gruppo coinvolti nella partita,
                calcolato sulle skills (con algoritmi) di tipo micro del gruppo
                ruolo di riferimento, con la normalizzare dei valori delle
                skilla tra 0 e 1 sui giocatori coinvolti nella partita,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro group norm</strong>: index normalizzato tra 0 e
                100 sui giocatori dello stesso gruppo coinvolti nella partita,
                calcolato su tutte le skills (con algoritmi), con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo di riferimento (Micro/Weigth Player
                Setup)
              </p>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col cols="3">
        <b-form-select
          v-model="filters.group"
          :options="groups"
          value-field="id"
          text-field="name"
        />
      </b-col>
      <b-col cols="3">
        <b-form-select
          v-model="filters.team"
          :options="teams"
          value-field="id"
          text-field="name"
        />
      </b-col>
    </b-row>

    <b-table
      id="my-table"
      ref="table"
      sticky-header="600px"
      small
      striped
      dark
      responsive
      :fields="fields"
      :items="filteredItems"
      :tbody-tr-class="rowClass"
    >
      <template #cell(index)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.index + 1 }}
        </span>
      </template>
      <template #cell(player)="data">
        <span style="width: 200px; white-space: nowrap; overflow: hidden">
          <b-row>
            <b-col cols="1">
              <b-form-checkbox
                name="games_sel"
                v-model="index_game_ids"
                :value="data.item.id"
              >
              </b-form-checkbox>
            </b-col>
            <b-col>
              <router-link
                v-if="auth.isUserEnable('opta')"
                :to="{
                  name: 'ScoutingViewPlayer',
                  params: { id: data.value.id },
                }"
                target="_blank"
                style="cursor: pointer"
              >
                <span
                  :style="
                    index_game_ids.includes(data.item.id) ? 'color:#000000' : ''
                  "
                >
                  {{ data.value.player }}
                </span>
              </router-link>
              <span
                v-else
                :style="
                  index_game_ids.includes(data.item.id) ? 'color:#000000' : ''
                "
              >
                {{ data.value.player }}
              </span>
            </b-col>
          </b-row>
        </span>
      </template>
      <template #cell(team_id)="data">
        {{ getTeam(data.value) }}
      </template>
      <template #cell(date)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value | formatDate }}
        </span>
      </template>
      <template #cell(micro_index_basic)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_basic_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_basic)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_basic_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_raw)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_raw_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_raw)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_raw_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index)="data">
        <div
          :style="'color:#000000;background:' + data.item['micro_index_color']"
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index)="data">
        <div
          :style="'color:#000000;background:' + data.item['macro_index_color']"
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_norm)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_norm)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_group_norm)="data">
        <div
          :style="
            'color:#000000;background:' +
            data.item['micro_index_group_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_group_norm)="data">
        <div
          :style="
            'color:#000000;background:' +
            data.item['macro_index_group_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(group)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value }}
        </span>
      </template>
      <template #cell(minutes)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value }}
        </span>
      </template>
    </b-table>

    <div
      v-for="(array_princ, index_princ) in principles_skills"
      :key="'princ_' + index_princ"
    >
      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card no-body class="m-1 h-100 text-center">
            <a
              style="text-decoration: none"
              v-b-toggle
              :href="'#monitoring-' + index_princ"
              @click.prevent
              ><h2 class="mb-0 mt-1">
                {{ principles_title[index_princ] }}
              </h2></a
            >
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible :id="'monitoring-' + index_princ">
        <b-row>
          <b-col
            sm="12"
            v-for="key in array_princ"
            :key="'line_' + key"
            class="p-0 mb-2"
          >
            <b-card class="m-1 h-100">
              <b-row v-if="skills.length > 0">
                <b-col cols="12" style="font-size: 0.85rem">
                  {{ getSkillById(key).label }}
                </b-col>
                <b-col cols="12">
                  <vertical-bar-ranking
                    v-if="ranking_data(key).length > 0"
                    :id="'rank_' + key"
                    :data="ranking_data(key)"
                  />
                </b-col>
                <b-col cols="12" class="mb-2">
                  <b-input-group prepend="Question">
                    <b-form-input
                      v-model="questions[key].question"
                      type="text"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="12" class="mb-2">
                  <b-input-group prepend="Domanda">
                    <b-form-input
                      v-model="questions[key].domanda"
                      type="text"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="6">
                  <div class="text-center">
                    <strong>{{ game.team_home.name }}</strong>
                  </div>
                  <b-form-checkbox-group
                    v-model="questions[key].players_home"
                    :name="'check_' + key"
                  >
                    <b-form-checkbox
                      v-for="player in players_home"
                      :value="player.id"
                      :key="key + '_ ' + player.id"
                      >{{ player.name }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-col>
                <b-col cols="6">
                  <div class="text-center">
                    <strong>{{ game.team_away.name }}</strong>
                  </div>
                  <b-form-checkbox-group
                    v-model="questions[key].players_away"
                    :name="'check_' + key"
                  >
                    <b-form-checkbox
                      v-for="player in players_away"
                      :value="player.id"
                      :key="key + '_ ' + player.id"
                      >{{ player.name }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-col>
                <b-col class="mt-3">
                  <b-button variant="primary">Save</b-button>
                </b-col>
                <!--b-col
                  cols="12"
                  class="mb-2"
                  :key="'ms_' + '_' + key + '_' + random"
                >
                  <multiselect
                    v-model="questions[key].players"
                    :multiple="true"
                    :options="players_options"
                    group-values="players"
                    group-label="team"
                    :group-select="true"
                    placeholder="Players"
                    label="name"
                    track-by="id"
                    selectLabel=""
                    deselectLabel=""
                    @input="onChange"
                  />
                </b-col-->
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
  </b-container>
</template>

<script>
import moment from "moment";
import Auth from "@/lib/auth.js";
//import Multiselect from "vue-multiselect";
import loading from "@/assets/images/loading.gif";
import VerticalBarRanking from "@/components/charts/VerticalBarRanking.vue";
export default {
  data: function () {
    return {
      ita: true,
      form: {
        //player_id: this.$route.params.id,
        game_id: this.$route.params.game_id,
        from: null,
        to: null,
        cluster_ids: [],
        group: null,
        minutes: 300,
        age_from: 0,
        age_to: 0,
      },
      groups: [
        { id: null, name: "-- Filter role group --" },
        { id: 0, name: "PORTIERI" },
        { id: 1, name: "DIFENSORI CENTRALI" },
        { id: 2, name: "ESTERNI" },
        { id: 3, name: "CENTROCAMPISTI CENTRALI" },
        { id: 4, name: "INTERNI DI CENTROCAMPO" },
        { id: 5, name: "ATTACCANTI CENTRALI" },
        { id: 6, name: "ATTACCANTI ESTERNI" },
      ],
      minutes: [300, 600, 900],
      loading,
      waiting: false,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "player",
          sortable: true,
          label: "Player",
          thStyle: { "min-width": "150px" },
        },
        {
          key: "team_id",
          sortable: true,
          label: "Team",
          //    thStyle: { "min-width": "100px" },
        },
        {
          key: "micro_index_basic",
          sortable: true,
          label: "Micro basic",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_basic",
          sortable: true,
          label: "Macro basic",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_raw",
          sortable: true,
          label: "Micro raw",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_raw",
          sortable: true,
          label: "Macro raw",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index",
          sortable: true,
          label: "Micro",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index",
          sortable: true,
          label: "Macro",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_norm",
          sortable: true,
          label: "Micro norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_norm",
          sortable: true,
          label: "Macro norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_group_norm",
          sortable: true,
          label: "Micro group norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_group_norm",
          sortable: true,
          label: "Macro group norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        { key: "group", sortable: true, label: "Group", class: "text-center" },
        { key: "minutes", sortable: true, label: "Min", class: "text-center" },
      ],
      waiting_index: false,
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
        "#FF9999",
      ],
      filter: null,
      indeterminate: false,
      player_index_games: [],
      team: null,
      index_game_ids: [],
      game: null,
      teams: [],
      filters: {
        group: null,
        team: null,
      },
      stats_range: null,
      skills: [],
      players: [],
      team_players: null,
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "GOALKEEPER",
        "SET PLAY",
      ],
      principles_skills: [[], [], [], [], [], [], [], [], [], [], [], [], []],
      questions: {},
      random: 0,
    };
  },

  components: {
    VerticalBarRanking,
    //   Multiselect,
  },

  created: function () {
    this.getOptaTeam();
    this.getPlayerIndexGame();
    this.getSkillsPrinciples();
    //  this.getOptaTeamGamePlayersStats();
  },

  watch: {},

  methods: {
    getOptaTeam() {
      this.$http
        .get(
          "/opta/team/" +
            (this.auth.isUserEnable("teams_milan")
              ? "131"
              : this.$route.params.id)
        )
        .then((response) => {
          this.team = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getOptaTeamGamePlayersStats() {
      this.$http
        .get("/opta/team/game/players/stats/" + this.$route.params.game_id)
        .then((response) => {
          this.stats_range = response.data.stats;
          this.skills = response.data.skills;
          this.players = response.data.players;
          this.team_players = response.data.team_players;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSkillsPrinciples() {
      this.principles_skills = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      this.$http.get("/opta/game/skills/principles").then((response) => {
        var principles = response.data;
        for (var i in principles) {
          var item = principles[i];
          this.principles_skills[item.principio].push(item.skill_id);
          this.questions[item.skill_id] = {
            question: "",
            domanda: "",
            players_home: [],
            players_away: [],
          };
        }
        this.getOptaTeamGamePlayersStats();
      });
    },

    getPlayerIndexGame() {
      if (
        this.form.game_id == null ||
        this.form.game_id === "" ||
        isNaN(this.form.game_id)
      ) {
        alert("Insert a valid player ID!");
        return;
      }
      this.waiting_index = true;
      this.player_index_games = [];
      this.index_game_ids = [];

      this.$http.post("/players/index/by/game", this.form).then((response) => {
        this.game = response.data.game;
        this.player_index_games = response.data.player_index_games;
        this.teams.push({ id: null, name: "-- Filter team --" });
        this.teams.push({
          id: this.game.team_home_id,
          name: this.game.team_home.name,
        });
        this.teams.push({
          id: this.game.team_away_id,
          name: this.game.team_away.name,
        });

        var array_micro_basic = [];
        var array_macro_basic = [];
        var array_micro_raw = [];
        var array_macro_raw = [];
        var array_micro = [];
        var array_macro = [];
        var array_micro_norm = [];
        var array_macro_norm = [];
        var array_micro_group_norm = [];
        var array_macro_group_norm = [];

        for (var i in this.player_index_games) {
          var item = this.player_index_games[i];
          array_micro_basic.push(item.micro_index_basic);
          array_macro_basic.push(item.macro_index_basic);
          array_micro_raw.push(item.micro_index_raw);
          array_macro_raw.push(item.macro_index_raw);
          array_micro.push(item.micro_index);
          array_macro.push(item.macro_index);
          array_micro_norm.push(item.micro_index_norm);
          array_macro_norm.push(item.macro_index_norm);
          array_micro_group_norm.push(item.micro_index_group_norm);
          array_macro_group_norm.push(item.macro_index_group_norm);
        }

        array_micro_basic.sort(function (a, b) {
          return b - a;
        });
        array_macro_basic.sort(function (a, b) {
          return b - a;
        });
        array_micro_raw.sort(function (a, b) {
          return b - a;
        });
        array_macro_raw.sort(function (a, b) {
          return b - a;
        });
        array_micro.sort(function (a, b) {
          return b - a;
        });
        array_macro.sort(function (a, b) {
          return b - a;
        });
        array_micro_norm.sort(function (a, b) {
          return b - a;
        });
        array_macro_norm.sort(function (a, b) {
          return b - a;
        });
        array_micro_group_norm.sort(function (a, b) {
          return b - a;
        });
        array_macro_group_norm.sort(function (a, b) {
          return b - a;
        });

        for (var i in this.player_index_games) {
          var item = this.player_index_games[i];

          var color_micro_index_basic = this.getBgStatIndex(
            item.micro_index_basic,
            array_micro_basic
          );
          item["micro_index_basic_color"] = color_micro_index_basic;

          var color_macro_index_basic = this.getBgStatIndex(
            item.macro_index_basic,
            array_macro_basic
          );
          item["macro_index_basic_color"] = color_macro_index_basic;

          var color_micro_index_raw = this.getBgStatIndex(
            item.micro_index_raw,
            array_micro_raw
          );
          item["micro_index_raw_color"] = color_micro_index_raw;

          var color_macro_index_raw = this.getBgStatIndex(
            item.macro_index_raw,
            array_macro_raw
          );
          item["macro_index_raw_color"] = color_macro_index_raw;

          var color_micro_index = this.getBgStatIndex(
            item.micro_index,
            array_micro
          );
          item["micro_index_color"] = color_micro_index;

          var color_macro_index = this.getBgStatIndex(
            item.macro_index,
            array_macro
          );
          item["macro_index_color"] = color_macro_index;

          var color_micro_index_norm = this.getBgStatIndex(
            item.micro_index_norm,
            array_micro_norm
          );
          item["micro_index_norm_color"] = color_micro_index_norm;

          var color_macro_index_norm = this.getBgStatIndex(
            item.macro_index_norm,
            array_macro_norm
          );
          item["macro_index_norm_color"] = color_macro_index_norm;

          var color_micro_index_group_norm = this.getBgStatIndex(
            item.micro_index_group_norm,
            array_micro_group_norm
          );
          item["micro_index_group_norm_color"] = color_micro_index_group_norm;

          var color_macro_index_group_norm = this.getBgStatIndex(
            item.macro_index_group_norm,
            array_macro_group_norm
          );
          item["macro_index_group_norm_color"] = color_macro_index_group_norm;
        }

        this.waiting_index = false;
      });
    },

    getBgStatIndex(value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (index == 0 && value > parseFloat(array_range[i])) {
            return this.bg_opta[index];
          }
          if (index == 9 && value < parseFloat(array_range[to])) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i]) &&
            value >= parseFloat(array_range[to])
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }

        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    onFiltered(filteredItems) {},

    rowClass(item, type) {
      if (!item || type !== "row") {
        return "";
      }
      if (this.index_game_ids.includes(item.id)) {
        return "table-success";
      }
      return "";
    },

    getTeam(team_id) {
      var team = "";
      if (this.game && this.game.team_home_id == team_id) {
        team = this.game.team_home.name;
      } else if (this.game && this.game.team_away_id == team_id) {
        team = this.game.team_away.name;
      }
      return team;
    },
    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },
    ranking_data(skill_id) {
      var data = [];
      var array_range = this.stats_range[skill_id];
      var skill = this.getSkillById(skill_id);
      /* if (skill.reverse) {
        array_range = this.stats_range[skill_id].slice().reverse();
      } */
      for (var i in array_range) {
        var item = array_range[i];
        var player = this.getPlayerById(item.player_id);
        data.push({
          score: item.skill_value_norm,
          color: this.getBgStat(skill_id, item.skill_value_norm),
          player: parseInt(i) + 1 + " - " + player.name,
          avatar: player.avatar ? player.avatar : "/assets/images/profile.png",
          colorLabel: "#FFFFFF",
          //value: item.skill_value_not_90,
          value: item.skill_value,
          //rank: parseInt(i),
        });
      }
      return data;
    },

    getPlayerById(id) {
      return this.players.find((player) => player.id == id);
    },
    getBgStat(skill_id, value) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var skill = this.getSkillById(skill_id);
        var array_range = this.stats_range[skill_id];
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        /*   if (skill.reverse) {
          for (var i = count_array - 1; i >= 0; i -= interval) {
            var to = i - interval;
            if (i - interval < 0) {
              to = 0;
            }
            if (index == 0 && value < parseFloat(array_range[i].skill_value)) {
              return this.bg_opta[index];
            }
            if (index == 9 && value > parseFloat(array_range[to].skill_value)) {
              return this.bg_opta[index];
            }
            if (
              value >= parseFloat(array_range[i].skill_value) &&
              value <= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        } else { */
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (
            index == 0 &&
            value > parseFloat(array_range[i].skill_value_norm)
          ) {
            return this.bg_opta[index];
          }
          if (
            index == 9 &&
            value < parseFloat(array_range[to].skill_value_norm)
          ) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i].skill_value_norm) &&
            value >= parseFloat(array_range[to].skill_value_norm)
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }
        //   }
        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    onChange(value) {
      this.random = Math.random();
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },

    filteredItems: function () {
      var filtered = this.player_index_games;
      if (this.filters.team) {
        filtered = filtered.filter((item) => item.team_id == this.filters.team);
      }
      if (this.filters.group !== null) {
        filtered = filtered.filter((item) => item.group == this.filters.group);
      }
      return filtered;
    },

    /*  players_options: function () {
      var options = [];

      var team_home = this.game.team_home;

      var players_home = [];

      for (var i in this.players) {
        if (this.team_players[this.players[i].id] == team_home.id) {
          players_home.push(this.players[i]);
        }
      }
      options.push({
        team: team_home.name,
        players: players_home,
      });

      var team_away = this.game.team_away;

      var players_away = [];

      for (var i in this.players) {
        if (this.team_players[this.players[i].id] == team_away.id) {
          players_away.push(this.players[i]);
        }
      }
      options.push({
        team: team_away.name,
        players: players_away,
      });

      return options;
    }, */

    players_home: function () {
      var team_home = this.game.team_home;

      var players_home = [];

      for (var i in this.players) {
        if (this.team_players[this.players[i].id] == team_home.id) {
          players_home.push(this.players[i]);
        }
      }
      return players_home;
    },

    players_away: function () {
      var team_away = this.game.team_away;

      var players_away = [];

      for (var i in this.players) {
        if (this.team_players[this.players[i].id] == team_away.id) {
          players_away.push(this.players[i]);
        }
      }
      return players_away;
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
